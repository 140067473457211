body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container{
  display: flex;
  flex-direction: column;
}

.form-container{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.table-container{  
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.form-button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.submit-btn{
  padding-top: 10px;
}


.MuiListItemText-primary{
  width: 450px;
}


.MuiFormControl-root{
  margin-top: 16px;
}


.about{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}


.signup{
  display: flex;
flex-direction: column;
padding-top: 100px;
justify-content: center;
align-items: center;
width: fit-content;
width: 50%;
margin: 0 auto;
}

.login-page{
  display: flex;
  flex-direction: row;
}

.login-info{
  padding-bottom: 30px;
  width: 400px;
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.125rem;
}

.loading{
  display: flex;
  justify-content: center;
  padding-top: 75px;
}

.feedback-form{
  display: flex;
  flex-direction: column;
  padding: 20px;
}